<template>

  <div class="container-fluid" v-if="!this.galeriaVer">
    <h3 class="text-light mt-3">Galeria</h3>
  </div>

  <div class="row justify-content-end mt-2">
    <div class="col-md-12">
      <div id="s3-content">
        <div
          :class="{
            card: true,
            'card-body': true,
          
          }"
        >
          <div class="row">
            <div class="col col-md-2">
              <label for="">Carpeta</label>
              <input
                type="text"
                class="form-control"
                placeholder="Nombre de la carpeta"
                v-model="objS3.carpeta"
              />
            </div>
            <div class="col col-md-3">
              <label for="">Video</label>
              <input
                type="file"
                accept="video/*"
                class="form-control"
                @change="onFileChangeVideo"
              />
            </div>
            <div class="col col-md-3">
              <label for="">Imgen</label>
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                class="form-control"
                @change="onFileChange"
              />
            </div>
            <div class="col col-md-2">
              <label for="">Filtro</label>
              <select
                name=""
                id=""
                class="form-select"
                @change="filtrar"
                v-model="idcarpeta"
              >
                <option value="" selected>Todas</option>
                <option
                  :value="car"
                  v-for="car of lstCarpetas"
                  v-bind:key="car"
                >
                  {{ car }}
                </option>
              </select>
            </div>
            <div class="col col-md-2" style="margin-block-start: auto">
              <button
                type="button"
                class="myButtonAceptar"
                @click="subirImagen()"
              >
                Subir
              </button>
            </div>
          </div>

          <div class="caja-s3">
            <div v-for="g of galerias" v-bind:key="g.id">
              <img
                v-if="g.tipo == 'img'"
                :src="getImg(g.id)"
                alt=""
                class="img-fluid"
                @click="modalImagen(g)"            
              />
              <img
                v-else
                src="img/video.png"
                alt=""
                class="img-fluid"
                @click="modalImagen(g)"              
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->

  <ModalGenerico :open="isOpen" @close="isOpen = !isOpen">
    <div class="modal-body">
      <div class="text-center">
        <div class="input-group mb-2">
          <input
            class="form-control"
            type="text"
            :value="rutaimg"
            style="width: 90%; font-size: 1em"
            id="textImg"
          />
          <button
            class="btn btn-success"
            type="button"
            @click="copyText()"          
          >
            <i class="fa fa-copy"></i>
          </button>
        </div>
        <img
          v-if="tipo == 'img'"
          :src="getImg(imgModal)"
          alt=""
          class="img-fluid"
        />
        <video
          v-if="tipo == 'video'"
          :src="getImg(imgModal)"
          width="300"
          controls
        ></video>
      </div>
      <div class="text-center mt-5">
        <button
          type="button"
          class="myButtonEliminar"
          @click="elimianrImagen()"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i style="color: #fff" class="far fa-trash-alt"></i>
        </button>
      </div>
    </div>
  </ModalGenerico>
</template>

<script>
import { inject, onMounted, reactive, ref } from "vue-demi";
import { useStore } from "vuex";
import useUtil from "../../../use/useUtil";
import { useRoute } from "vue-router";
import useGaleriaS3 from "../composable/useGaleriaS3";
import ModalGenerico from "../../../components/modal/modalGenerico.vue";

export default {
  components: { ModalGenerico },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isOpen = ref(false);

    const modalGaleria = inject("modalGaleria")

    const galeriaVer = ref(true);
    const {
      createImagen,
      getS3Carpetas,
      getImagen,
      galerias,
      lstCarpetas,
      deleteImagen,
    } = useGaleriaS3();
    const objS3 = reactive({
      carpeta: "",
      imagen: "",
      video: "",
    });

    const { getImg } = useUtil();
    const idcarpeta = ref("");
    const imgModal = ref("");
    const nombreImagen = ref("");
    const rutaimg = ref("");
    const idImagen = ref("");
    const tipo = ref("");

    const subirImagen = async () => {      
      store.commit("showLoader");
      await createImagen(objS3);
      await getS3Carpetas();
      await getImagen({
        carpeta: idcarpeta.value,
      });
      store.commit("hideLoader");
    };

    if (route.name == "Galeria") {
      galeriaVer.value = false;
    }

    const onFileChange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        objS3.imagen = e.target.result;
      };
    };
    const onFileChangeVideo = (e) => {
      // objS3.video = e.target.files[0];
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        objS3.video = e.target.result;
      };
    };

    onMounted(async () => {
      // if (!galerias.value) {
      store.commit("showLoader");
      await getS3Carpetas();
      await getImagen({
        carpeta: idcarpeta.value,
      });
      store.commit("hideLoader");
      // }
    });

    const modalImagen = (img) => {
      imgModal.value = img.id;
      idImagen.value = img.id;
      nombreImagen.value = img.id.split("/")[2];
      tipo.value = img.tipo;
      rutaimg.value = process.env.VUE_APP_URL_IMG + img.id;
      isOpen.value = true;
    };

    const elimianrImagen = async () => {
      store.commit("showLoader");
      isOpen.value = false;
      let obj = { imagen: idImagen.value };
      await deleteImagen(obj);
      await getS3Carpetas();
      await getImagen({
        carpeta: idcarpeta.value,
      });
      store.commit("hideLoader");
    };

    const filtrar = async (e) => {
      store.commit("showLoader");
      objS3.carpeta = e.target.value;
      await getImagen({
        carpeta: e.target.value,
      });
      store.commit("hideLoader");
    };

    const copyText = () => {
      let content = document.getElementById("textImg");
      content.select();
      document.execCommand("copy");
      navigator.clipboard.writeText(rutaimg.value);
      isOpen.value = false;
      modalGaleria.value = false;
    };

    return {
      objS3,
      subirImagen,
      onFileChange,
      galerias,
      getImg,
      lstCarpetas,
      filtrar,
      idcarpeta,
      modalImagen,
      imgModal,
      nombreImagen,
      elimianrImagen,
      galeriaVer,
      onFileChangeVideo,
      tipo,
      copyText,
      rutaimg,      
      isOpen,
    };
  },
};
</script>

<style  scoped>
.caja-s3 {
  display: flex;
  flex-wrap: wrap;
}
.caja-s3 > div {
  width: 100px;
  height: 100px;
  margin: 30px;
}
.caja-s3 > div > img {
  /* width: 150px;
  height: 150px;
  padding: 30px; */
  cursor: pointer;
}
.contenedor-s3 {
  height: 300px !important;
  overflow: auto !important;
}
</style>
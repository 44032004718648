import { computed } from "vue";
import { useStore } from "vuex";

const useGaleriaS3 = () => {
  const store = useStore();

  const createImagen = async (obj) => {
    const resp = await store.dispatch("galeria/createImagen", obj);
    return resp;
  };
  const getS3Carpetas = async (obj) => {
    const resp = await store.dispatch("galeria/getS3Carpetas", obj);
    return resp;
  };
  const getImagen = async (obj) => {
    const resp = await store.dispatch("galeria/getImagen", obj);    
    return resp;
  };
  const deleteImagen = async (obj) => {
    const resp = await store.dispatch("galeria/deleteImagen", obj);    
    return resp;
  };

  return {
    createImagen,
    getS3Carpetas,
    getImagen,
    deleteImagen,
    galerias: computed(() => store.getters["galeria/getS3galeria"]),
    lstCarpetas: computed(() => store.getters["galeria/getS3carpetas"]),   
  };
};

export default useGaleriaS3;

<template>
  <transition name="fade">
    <div class="vue-modal" v-show="open">
      <div :style="{ 'max-width': ancho, margin:'2rem auto' }">
        <div class="vue-modal-content">
          <div class="text-end">
            <button
              type="button"
              @click="$emit('close')"
              class="btn-close"
            ></button>
          </div>
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
import { onMounted, onUnmounted } from "vue-demi";
export default {
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    ancho:{
      type:String,
      default:'500px'
    }
  },

  setup(_, { emit }) {
    const close = () => {
      emit("close");
    };

    const handleKeyup = (event) => {
      if (event.keyCode === 27) {
        close();
      }
    };

    onMounted(() => document.addEventListener("keyup", handleKeyup));
    onUnmounted(() => document.removeEventListener("keyup", handleKeyup));

    return { close };
  },
};
</script>

<style>
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.vue-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10000;
}

/* .vue-modal-inner {
  max-width: 500px;
  margin: 2rem auto;
} */

.vue-modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  border-radius: 0.3rem;
  padding: 1rem;
}

.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active {
  transition: all 0.5s ease;
}

.fade-leave-from {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all 0.5s ease;
}
</style>